<template>
    <div class="d-flex">
        <section class="w-50 pe-4">
            <h1 class="mb-2">Knowledge base</h1>
            <FormItem type="text" v-model="item.title" :label="$t('overview.title')" />
            <FormItem type="textarea" v-model="item.problem_description" :label="$t('form.problem_description')" />
            <FormItem type="textarea" v-model="item.solution_description" :label="$t('form.solution_description')" />
            <FormItem type="text" v-model="item.extra_information_url" :label="$t('form.extra_information_url')" />
            <FormItem type="text" v-model="item.issue_origin" :label="$t('form.issue_origin')" disabled/>
            <h1 class="mb-2 mt-4">Melding</h1>
            <pre>{{item.issue_text}}</pre>
        </section>
    </div>
    <FooterButtons @prev="prev" @save="save" :disabled="isSaving" />
</template>

<script>
    import KnowledgeBaseService from '@/services/KnowledgeBaseService';
    import FooterButtons from '@/components/FooterButtons.vue';
    export default {
        name: 'HoldingDetail',
        components: {
            FooterButtons
        },
        data() {
            return {
                item: {
                    "title": "",
                    "problem_description": "",
                    "solution_description": "",
                    "extra_information_url": "",
                    "issue_origin": "",
                    "issue_text": "",
                },
                isNew: true,
                isSaving: false
            }
        },
        methods: {
            validate(){
                //Validation
                const els = document.querySelectorAll("section input[type='text']:required, section select:required, section textarea:required");
                let valid = true;
                els.forEach(el => {
                    if(!el.value){
                        el.classList.add("is-invalid");
                        if(!el.classList.contains("has-onfocus")){
                            el.addEventListener("focus", (thisEl) => {
                                thisEl.target.classList.remove("is-invalid");
                            })
                            el.classList.add("has-onfocus");
                        }
                        valid = false;
                    }
                })
                return valid;
            },
            prev(){
                this.$router.push({'name': "Knowledge Base"});
            },
            save() {
                if(!this.validate()){
                    return;
                }
                this.isSaving = true;
                KnowledgeBaseService.updateOrStore(this.item).then(response => {
                    this.$router.push({'name': "Knowledge Base"});
                }).catch(e => {
                    this.isSaving = false;
                    this.toastError(e);
                });
            }
        },
        mounted(){
            const locationId = this.$route.params.id;
            this.isNew = locationId == 'nieuw';
            if(!this.isNew){
                KnowledgeBaseService.show(locationId).then(response => {
                    this.item = response.data;
                });
            }
        }
    }

</script>